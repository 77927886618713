#contentContainer {
    margin: 5px;
}
#actionBar {
    display: flex;
    flex-direction: row;
    justify-content: flex-start ;
    justify-items: end;
    width: 100%;
}

#actionBar a {
    margin-right: 4px;
    padding: 2px 5px 2px 5px;
    border: 2px solid black;
    background-color: #e5e5e5;
}

#imagesContainer {
    max-width: 100%;
    margin-top: 10px;
    display: inline-flex;
    border: 3px solid;
}

.image-container {
    border: 1px solid;
    object-fit: contain;
}

.image-detail {
    border-bottom: 1px dashed;
}

.image-container img {
    width: 100%;
    object-fit: contain;
}

#footer {
    float: right;
    padding-top: 20px;
    padding-bottom: 30px;
}

#overlay {
    position: fixed;
    display: none;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(255, 255, 255, 0.8);
    z-index: 999;
}

#overlay.visible {
    display: flex;
    justify-content: center;
    align-items: center;
}

.loader {
    border: 5px solid #f3f3f3;
    border-radius: 50%;
    border-top: 5px solid #3498db;
    width: 50px;
    height: 50px;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
