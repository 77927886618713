#jobContainer {
    display: flex;
    flex-direction: row;
    height: 100%;
}

#jobOutletContainer {
    height: 100%;
}

#diffsSidebarContainer {
    position: relative;
    height: 100%;
    border-right: 1px solid #ccc;
    overflow: hidden;  
    background-color: #e5e5e5;
    min-width: 30px;
    display: flex;  
    flex-direction: column;
}

#diffsSidebarContainer ul {
    margin: 0;
    padding: 0;
    overflow-y: auto;  
    overflow-x: auto;  
    flex-grow: 1;  
    width: 100%;
}

#diffsSidebarContainer li {
    display: block;
    width: 100%;
}

#diffsSidebarContainer a {
    display: block;
    text-decoration: none;
    color: inherit;
    padding: 4px;
    min-width: max-content; 
}

#sidebarResizeHandle {
    position: absolute;
    right: 0;
    top: 0;
    width: 4px;
    height: 100%;
    background-color: #111111;
    cursor: col-resize;
}

#sidebarResizeHandle:hover {
    background-color: #999;
}

#sidebarButtonContainer,
#sidebarCollapseButton {
    display: none;
}

.selected {
    background-color: #c8c8c8;
    border-radius: 4px;
}

.selected a {
    font-weight: bold;
}
