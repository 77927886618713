html, body {
    margin: 0px;
    padding: 0px;
    height: 100%;
}

#root {
    display: flex;
    flex-direction: column;
    height: 97%;
    width: 97%;
    padding: 20px 10px 0;
}

#headerContainer {
    display: flex;
    height: auto;
    width: auto;
    border: 1px solid black;
    padding-bottom: 0;
    flex-direction: column;
}

#headerContainer h1 {
    height: 50%;
    margin: 10px 0 10px 10px;
}

#mainContent {
    margin-top: 20px;
    border: 1px solid black;
    height: 100%;
    width: 100%;
}

.full-width {
    flex: 1;
}

/* Breadcrumb */
.breadcrumb {
    display: flex;
    height: auto;
    background-color: #f1f1f1;
    padding: 8px;
    border-top: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    align-items: center;
}

.breadcrumb .separator {
    text-align: center;
}

.breadcrumb a {
    text-decoration: none;
    color: #0000EE;
    margin-right: 8px;
    margin-left: 8px;
}

.breadcrumb a:hover {
    text-decoration: underline;
}
