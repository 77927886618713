#jobsOutletContainer {
    height: 100%;
    width: 100%;
    padding: 0 0 0 0;
}

#jobsOutletContainer h1 {
    padding-left: 10px;
}

#jobsOutletContainer ul {
    width: 100%;
    height: 100%;
    border-top: 1px solid black;
    padding: 0 0 0 0;
}

#jobsOutletContainer li {
    display: inline-block;
    height: auto;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 10px 0 10px 0;
    border-bottom: 1px solid black;
}

#jobsOutletContainer .status-bullet {
    position: relative;
    bottom: 10px;
}

#jobsOutletContainer .list-content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: calc(100% - 26px);
}

#jobsOutletContainer a {
    display: inline-block;
    margin: auto 10px auto 10px;
}

#jobsOutletContainer .job-dates {
    display: inline-flex;
    position: relative;
    flex-direction: column;
    width: auto;
    margin: auto 0 0 auto;
    padding-right: 10px;
}

#jobsOutletContainer .job-dates .create-date {
    margin-left: auto;
    margin-right: 0;
}

#jobsOutletContainer .primary-job-ref {
    margin-bottom: 2px;
}

#jobsOutletContainer .status-bullet {
    margin-top: 20px;
}
